import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Header from "../components/Header"

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <Header />
    <section className="container page">
      <div className="row">
        <div className="col-12">
          <h1>Page not found</h1>
          <p>This page doesn't exist :(</p>
          <p>If you believe this to be in error and would like to let us know, you can go <a href="/contact">HERE</a> to let us know.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
